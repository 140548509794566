<template>
  <ValidationObserver ref="observer">
    <ValidationProvider v-slot="{ errors }" name="Comment" rules="required">
      <v-toolbar flat>
        <v-text-field
          label="Type a comment..."
          single-line
          solo
          rounded
          ref="commentForm"
          class="mt-6 pa-3 align-center"
          :error-messages="errors"
          @keyup.enter="sendComment"
          v-model="item.comment"
          name="Comment"
        ></v-text-field>
        <v-btn
          fab
          :disabled="item.comment == ''"
          @click.native="sendComment"
          color="primary"
          :loading="loading"
        >
          <v-icon>mdi-send</v-icon>
        </v-btn>
      </v-toolbar>
    </ValidationProvider>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  $_veeValidate: {
    validator: "new"
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    type: {
      type: String,
      required: true
    },
    typeId: {
      default: null,
      required: true
    },
    editId: {
      default: null,
      required: false
    },
    editComment: {
      default: null,
      required: false
    },
    editItem: {
      default: null,
      required: false
    }
  },

  data() {
    return {};
  },
  computed: {
    item() {
      const comment = this.editComment || "";
      return {
        type: this.type,
        typeId: this.typeId,
        comment: comment
      };
    },
    loading: {
      get() {
        return this.$store.getters["comments/loading"];
      }
    }
  },
  watch: {
    editId() {
      this.$refs.commentForm.focus();
    }
  },
  methods: {
    async sendComment() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        if (this.editId) {
          const itemApi = { ...this.editItem, ...this.item };
          this.$store.dispatch("comments/updateItem", itemApi).then(() => {
            this.item.comment = "";
            this.$refs.commentForm.reset();
          });
        } else {
          this.$store.dispatch("comments/insertItem", this.item).then(() => {
            this.item.comment = "";
            this.$refs.commentForm.reset();
          });
        }
      } else {
        this.$store.dispatch("options/setOptionsData", {
          message:
            "There is some form validation errors. Please fix the red form fields!",
          snackbar: true,
          color: "red"
        });
      }
    }
  }
};
</script>
